import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './FormularioBitacora.css';

const FormularioBitacora = () => {
  return (
    <Form className="formulario-bitacora">
      <Row>
        <Col md={6}>
          <Form.Group controlId="formPersonal">
            <Form.Label>Personal</Form.Label>
            <Form.Control type="text" placeholder="Nombre del Personal" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="formProyecto">
            <Form.Label>Proyecto</Form.Label>
            <Form.Control as="select">
              <option>Proyecto 1</option>
              <option>Proyecto 2</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formActividad">
            <Form.Label>Actividad</Form.Label>
            <Form.Control type="text" placeholder="Descripción de la Actividad" />
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit">
        Registrar Actividad
      </Button>
    </Form>
  );
};

export default FormularioBitacora;
