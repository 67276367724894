import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './MainContent.css';

const MainContent = () => {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.get('https://testpb.smci.com.mx/backend/get_roles.php')
      .then(response => {
        if (response.data && response.data.roles) {
          setRoles(response.data.roles);
        } else {
          setError('No se encontraron roles.');
        }
      })
      .catch(error => {
        setError('Error al obtener los roles: ' + error.message);
      });
  }, []);

  return (
    <div className="main-content">
      <h2>Dashboard</h2>
      <h3>Roles</h3>
      {error && <p>{error}</p>}
      <ul>
        {roles.map(role => (
          <li key={role.id}>{role.descripcion}</li>
        ))}
      </ul>
    </div>
  );
};

export default MainContent;
