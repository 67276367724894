import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Tareas from './pages/tareas/Tareas';
import Bitacora from './pages/bitacora/Bitacora';
import Monitoreo from './pages/monitoreo/Monitoreo';
import Personal from './pages/personal/Personal';
import Roles from './pages/roles/Roles';
import Prefijos from './pages/prefijos/Prefijos';
import Proyectos from './pages/proyectos/Proyectos';
import Auditoria from './pages/auditoria/Auditoria';
import Pagos from './pages/pagos/Pagos';
import Registros from './pages/registros/Registros';
import Control from './pages/control/Control';
import Fotos from './pages/fotos/Fotos';
import Galeria from './pages/galeria/Galeria';
import Unidades from './pages/unidades/Unidades';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
