// src/pages/proyectos/Proyectos.js

import React from 'react';

const Proyectos = () => {
  return (
    <div className="main-content">
      <h1>Proyectos</h1>
      <p>Contenido de Proyectos...</p>
    </div>
  );
};

export default Proyectos;
