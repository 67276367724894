import React from 'react';
import { Table } from 'react-bootstrap';
import './TablaBitacora.css';

const TablaBitacora = () => {
  return (
    <div className="tabla-bitacora">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Proyecto</th>
            <th>Actividad</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Proyecto 1</td>
            <td>Descripción de la actividad 1</td>
            <td>01/01/2023</td>
          </tr>
          {/* Agrega más filas según sea necesario */}
        </tbody>
      </Table>
    </div>
  );
};

export default TablaBitacora;
