// src/pages/prefijos/Prefijos.js

import React from 'react';

const Prefijos = () => {
  return (
    <div className="main-content">
      <h1>Prefijos</h1>
      <p>Contenido de Prefijos...</p>
    </div>
  );
};

export default Prefijos;
