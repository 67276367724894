// src/pages/personal/Personal.js

import React from 'react';

const Personal = () => {
  return (
    <div className="main-content">
      <h1>Personal</h1>
      <p>Contenido de Personal...</p>
    </div>
  );
};

export default Personal;
