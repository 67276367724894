import React from 'react';
import { Table, Form } from 'react-bootstrap';
import './TablaTareas.css';

const TablaTareas = () => {
  return (
    <div className="task-table">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Proyecto</th>
            <th>Descripción</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Prioridad</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Proyecto 1</td>
            <td>Descripción de la tarea 1</td>
            <td>01/01/2023</td>
            <td>
              <Form.Control as="select" defaultValue="Pendiente">
                <option>Pendiente</option>
                <option>En Proceso</option>
                <option>Completado</option>
              </Form.Control>
            </td>
            <td>Alta</td>
          </tr>
          {/* Agrega más filas según sea necesario */}
        </tbody>
      </Table>
    </div>
  );
};

export default TablaTareas;
