import React from 'react';
import './Proceso.css';
import procesoImg from './en_proceso.jpg';

const Proceso = () => {
  return (
    <div className="proceso-container">
      <img src={procesoImg} alt="En Proceso" className="proceso-imagen" />
      <h2>Apartado en Proceso</h2>
      <p>Se esta trabajando arduamente en crear este apartado, mire la foto de mientras.</p>
    </div>
  );
};

export default Proceso;
