import React, { useState } from 'react';
import FormularioTareas from '../../components/tareas/FormularioTareas';
import TablaTareas from '../../components/tareas/TablaTareas';
import './Tareas.css';

const Tareas = ({ canAssignTasks }) => {
  return (
    <div className="tareas-container">
      {/* {canAssignTasks && <TaskForm />} */}
      <FormularioTareas/>
      <TablaTareas />
    </div>
  );
};

export default Tareas;
