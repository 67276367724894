import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './FormularioTareas.css';

const FormularioTareas = () => {
  const [noDeadline, setNoDeadline] = useState(false);

  const handleNoDeadlineChange = () => {
    setNoDeadline(!noDeadline);
  };

  return (
    <Form className="task-form">
      <Row>
        <Col md={6}>
          <Form.Group controlId="formPersonal">
            <Form.Label>Personal</Form.Label>
            <Form.Control type="text" placeholder="Nombre del Personal" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="formProyecto">
            <Form.Label>Proyecto</Form.Label>
            <Form.Control as="select">
              <option>Proyecto 1</option>
              <option>Proyecto 2</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formDescripcion">
            <Form.Label>Descripción</Form.Label>
            <Form.Control type="text" placeholder="Descripción de la Tarea" />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form.Group controlId="formNoDeadline">
            <Form.Check
              type="checkbox"
              label="Sin Fecha Establecida"
              onChange={handleNoDeadlineChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          {!noDeadline && (
            <Form.Group controlId="formFecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          )}
        </Col>
        <Col md={4}>
          <Form.Group controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Form.Control as="select">
              <option>Pendiente</option>
              <option>En Proceso</option>
              <option>Completado</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formPrioridad">
            <Form.Label>Prioridad</Form.Label>
            <Form.Control as="select">
              <option>Alta</option>
              <option>Media</option>
              <option>Baja</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit">
        Asignar Tarea
      </Button>
    </Form>
  );
};

export default FormularioTareas;
