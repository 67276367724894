import React from 'react';
import './Topbar.css';
import logoutIcon from '../assets/images/logout.png';

const Topbar = () => {
  return (
    <div className="topbar-custom">
      <div className="topbar-info">
        <span className="username">Nombre del Usuario</span>
        <button className="logout-button">
          <img src={logoutIcon} alt="Cerrar sesión" className="logout-icon" />
        </button>
      </div>
    </div>
  );
};

export default Topbar;
