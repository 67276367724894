// src/pages/roles/Roles.js

import React from 'react';

const Roles = () => {
  return (
    <div className="main-content">
      <h1>Roles</h1>
      <p>Contenido de Roles...</p>
    </div>
  );
};

export default Roles;
