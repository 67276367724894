import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import Sidebar from '../../components/Sidebar';
import MainContent from '../../components/MainContent';
import Tareas from '../../pages/tareas/Tareas';
import Bitacora from '../../pages/bitacora/Bitacora';
import Monitoreo from '../../pages/monitoreo/Monitoreo';
import Personal from '../../pages/personal/Personal';
import Roles from '../../pages/roles/Roles';
import Prefijos from '../../pages/prefijos/Prefijos';
import Proyectos from '../../pages/proyectos/Proyectos';
import Auditoria from '../../pages/auditoria/Auditoria';
import Pagos from '../../pages/pagos/Pagos';
import Registros from '../../pages/registros/Registros';
import Control from '../../pages/control/Control';
import Fotos from '../../pages/fotos/Fotos';
import Galeria from '../../pages/galeria/Galeria';
import Unidades from '../../pages/unidades/Unidades';
import Proceso from '../../components/Proceso';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-section">
        <Topbar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          
          <Route path="tareas" element={<Tareas />} />
          {/* <Route path="tareas" element={<Proceso />} /> */}

          <Route path="bitacora" element={<Bitacora />} />
          {/* <Route path="bitacora" element={<Proceso />} /> */}

          {/* <Route path="monitoreo" element={<Monitoreo />} /> */}
          <Route path="monitoreo" element={<Proceso />} />

          <Route path="personal" element={<Personal />} />
          {/* <Route path="personal" element={<Proceso />} /> */}

          <Route path="roles" element={<Roles />} />
          {/* <Route path="roles" element={<Proceso />} /> */}

          <Route path="prefijos" element={<Prefijos />} />
          {/* <Route path="prefijos" element={<Proceso />} /> */}

          <Route path="proyectos" element={<Proyectos />} />
          {/* <Route path="proyectos" element={<Proceso />} /> */}

          {/* <Route path="auditoria" element={<Auditoria />} /> */}
          <Route path="auditoria" element={<Proceso />} />

          {/* <Route path="pagos" element={<Pagos />} /> */}
          <Route path="pagos" element={<Proceso />} />

          {/* <Route path="registros" element={<Registros />} /> */}
          <Route path="registros" element={<Proceso />} />

          {/* <Route path="control" element={<Control />} /> */}
          <Route path="control" element={<Proceso />} />

          {/* <Route path="fotos" element={<Fotos />} /> */}
          <Route path="fotos" element={<Proceso />} />

          {/* <Route path="galeria" element={<Galeria />} /> */}
          <Route path="galeria" element={<Proceso />} />

          {/* <Route path="unidades" element={<Unidades />} /> */}
          <Route path="unidades" element={<Proceso />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
