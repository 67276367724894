import React from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaTasks, FaBook, FaEye, FaBus, FaClipboardCheck, FaMoneyBill, FaRegListAlt, FaWrench, FaCamera, FaImages, FaUser, FaCogs, FaTags, FaProjectDiagram } from 'react-icons/fa';
import './Sidebar.css';
import logo from '../assets/images/logo.png';

const Sidebar = () => {
  return (
    <div className="sidebar-custom">
      <img src={logo} alt="Logo" className="sidebar-logo" />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>General</Accordion.Header>
          <Accordion.Body>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/dashboard">
                <FaTachometerAlt className="sidebar-icon" /> Dashboard
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/tareas">
                <FaTasks className="sidebar-icon" /> Tareas
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/bitacora">
                <FaBook className="sidebar-icon" /> Bitácora
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>SmartBus</Accordion.Header>
          <Accordion.Body>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/dashboard/monitoreo">
                <FaEye className="sidebar-icon" /> Monitoreo
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/unidades">
                <FaBus className="sidebar-icon" /> Unidades
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Publicitybus</Accordion.Header>
          <Accordion.Body>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/dashboard/pagos">
                <FaMoneyBill className="sidebar-icon" /> Pagos
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/registros">
                <FaRegListAlt className="sidebar-icon" /> Registros
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/control">
                <FaWrench className="sidebar-icon" /> Control
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/fotos">
                <FaCamera className="sidebar-icon" /> Fotos
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/galeria">
                <FaImages className="sidebar-icon" /> Galería
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Reportes</Accordion.Header>
          <Accordion.Body>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/dashboard/auditoria">
                <FaClipboardCheck className="sidebar-icon" /> Auditoría
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Configuración</Accordion.Header>
          <Accordion.Body>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/dashboard/personal">
                <FaUser className="sidebar-icon" /> Personal
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/roles">
                <FaCogs className="sidebar-icon" /> Roles
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/prefijos">
                <FaTags className="sidebar-icon" /> Prefijos
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/proyectos">
                <FaProjectDiagram className="sidebar-icon" /> Proyectos
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Sidebar;
