import React from 'react';
import FormularioBitacora from '../../components/bitacora/FormularioBitacora';
import TablaBitacora from '../../components/bitacora/TablaBitacora';
import './Bitacora.css';

const Bitacora = () => {
  return (
    <div className="bitacora-container">
      <FormularioBitacora />
      <TablaBitacora />
    </div>
  );
};

export default Bitacora;
